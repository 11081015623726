<template>
  <nav
    class="flex overflow-x-auto nav"
    :class="{ 'border-b': variant === 'stacked' }"
  >
    <ul
      class="relative grid grid-cols-[--cols] items-center"
      :style="{ '--cols': `repeat(${items.length}, minmax(min-content,1fr))` }"
    >
      <li
        v-show="activeTabIndex >= 0"
        aria-hidden="true"
        class="absolute top-0 left-0 h-full w-[--width] transition translate-x-[--offsetX]"
        :class="{
          'bg-primary-50': !dark,
          'bg-primary-900': dark,
          rounded: variant === undefined,
          'rounded-t after:absolute after:content-[\'\'] after:bottom-0 after:w-full after:border-b-2 after:border-primary-900':
            variant === 'stacked',
        }"
        :style="{
          '--offsetX': `${activeTabIndex * 100}%`,
          '--width': `calc(100%/${items.length})`,
        }"
      />
      <li v-for="(item, index) in items" :key="item.label" class="relative">
        <component
          :is="item.to ? NuxtLink : 'button'"
          class="w-full text-center block px-3 py-1 text-subtle rounded transition font-semibold hover:text-primary"
          :class="[
            { [activeClass]: activeTabIndex === index },
            tabClasses ?? '',
          ]"
          :to="item.to"
          @click="onClick(item)"
          >{{ item.label }}</component
        >
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import type { NavigationTabItem } from "../navigation.model";

const NuxtLink = resolveComponent("nuxt-link");

const properties = withDefaults(
  defineProps<{
    items: NavigationTabItem[];
    modelValue?: string | number | boolean;
    dark?: boolean;
    tabClasses?: string;
    variant?: "stacked";
  }>(),
  {
    variant: undefined,
    tabClasses: undefined,
    modelValue: undefined,
  },
);

const emit = defineEmits<{
  (event: "update:model-value", payload?: string | number | boolean): void;
}>();

const router = useRouter();
const route = useRoute();

const activeTabIndex = computed(() => {
  return properties.items.findIndex((item) => {
    const isItemValueActive =
      item.value !== undefined && item.value === properties.modelValue;
    const isItemRouteActive =
      item.to !== undefined && router.resolve(item.to).name === route.name;

    return isItemValueActive || isItemRouteActive;
  });
});

const activeClass = computed(() =>
  properties.dark ? "!text-white" : "!text-primary",
);

const onClick = (item: NavigationTabItem) => {
  if (item.value === undefined) return;

  emit("update:model-value", item.value);
};
</script>

<style scoped>
.nav::-webkit-scrollbar {
  display: none;
}
</style>
